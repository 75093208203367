export function settingCountriesTableHead () {
  return [
    {
      text: 'Id',
      align: 'start',
      value: 'id'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Slug',
      value: 'slug'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
