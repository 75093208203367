import { mapActions, mapGetters, mapMutations } from 'vuex'

import General from '../general/index.vue'
import Socials from '../socials/index.vue'
import Translations from '../translations/index.vue'

export default {
  name: 'setting-countries-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general'
    }
  },
  components: {
    General,
    Socials,
    Translations
  },
  watch: {
    openDialogView () {
      this.dialog = this.openDialogView
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'settingCountries/currentItem',
      openDialogView: 'settingCountries/openDialogView',
      isLoading: 'settingCountries/isLoading',
      basicLocales: 'setting/basicLocales'
    }),
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        {
          name: 'Translations',
          slug: 'translations',
          id: 2
        },
        {
          name: 'Socials',
          slug: 'socials',
          id: 3
        }
      ]
    }
  },
  created () {
    if (this.currentItem) {
      this.fetchItem({ id: this.currentItem.id })
    }
    if (!this.basicLocales.length) {
      this.fetchBasicLocales()
    }
  },
  methods: {
    ...mapActions({
      fetchItem: 'settingCountries/GET_LIST_ITEM',
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES',
    }),
    ...mapMutations({
      changeDialogView: 'settingCountries/CHANGE_DIALOG_VIEW',
      changeEditId: 'settingCountries/CHANGE_EDIT_ID',
      setContent: 'settingCountries/SET_ITEM'
    }),
    changeCloseDialog () {
      this.dialog = false
      this.changeDialogView(false)
    },

    save () {
      this.$refs.general.$emit('event-save')
    }
  },
  destroyed () {
    this.setContent(null)
  }
}
