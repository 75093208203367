import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'

import mixins from '@/mixins'
import validation from '@/mixins/validation'
import course from '@/mixins/course'

export default {
  name: 'setting-countries-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        slug: '',
        native: '',
        active: '',
        basic: '',
        sort: '',
        locales: []
      }
    }
  },
  watch: {
    currentItem: {
      handler () {
        if (this.currentItem) {
          this.setData()
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'settingCountries/isLoading',
      currentItem: 'settingCountries/currentItem',
      list: 'settingCountries/list',
      editId: 'settingCountries/editId',
      locales: 'setting/locales',
      localesLoading: 'setting/localesLoading'
    })
  },
  created () {
    if (this.currentItem) {
      this.setData()
    }
    if (!this.locales.length) {
      this.fetchLocales()
    }

    this.$on('event-save', () => {
      this.submit()
    })
  },
  methods: {
    ...mapActions({
      fetchLocales: 'setting/GET_LOCALES',
      update: 'settingCountries/UPDATE',
      create: 'settingCountries/CREATE'
    }),
    ...mapMutations({}),
    setData () {
      Object.assign(this.payload, this.currentItem)
      this.payload.locales = this.currentItem.locales.data.map(e => e.locale)
    },
    createRequestPayload () {
      const formData = new FormData()
      Object.keys(this.payload).forEach(e => {
        formData.append(snakeCase(e), this.payload[e])
      })
      this.payload.locales.forEach((e, index) => {
        formData.append(`locales[${index}][locale]`, e)
        formData.append(`locales[${index}][default]`, '0')
      })
      formData.append('active', this.payload.active ? 1 : 0)
      formData.append('basic', this.payload.basic ? 1 : 0)
      formData.append('_method', 'patch')
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.update(this.createRequestPayload()).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    }
  }
}
