import { mapActions, mapGetters, mapMutations } from 'vuex'
import DialogView from './dialog-view/index.vue'
import CountryFlag from 'vue-country-flag'
import { debounce } from 'lodash'
import { settingCountriesTableHead } from '../../helpers/index'

export default {
  name: 'setting-countries',
  data () {
    return {
      page: 1,
      filter: {
        keywords: ''
      }
    }
  },
  components: {
    DialogView,
    CountryFlag
  },
  computed: {
    ...mapGetters({
      accessCountries: 'profile/accessCountries',
      list: 'settingCountries/list',
      limit: 'settingCountries/limit',
      listLength: 'settingCountries/listLength',
      isLoading: 'settingCountries/isLoading',
      isListLoading: 'settingCountries/isListLoading',
      openDialogView: 'settingCountries/openDialogView'
    }),
    settingCountriesTableHead
  },
  created () {
    this.fetchList(this.filter)
  },
  methods: {
    ...mapActions({
      fetchList: 'settingCountries/GET_LIST'
    }),
    ...mapMutations({
      changeSkip: 'settingCountries/CHANGE_SKIP',
      changeDialogView: 'settingCountries/CHANGE_DIALOG_VIEW',
      setList: 'settingCountries/SET_LIST',
      setCurrentItem: 'settingCountries/SET_ITEM'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.sendRequest()
    },
    changeDialog (status = false, item = null) {
      this.changeDialogView(status)
      this.setCurrentItem(item)
    },
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 1000)
  },
  destroyed () {
    this.setList([])
  }
}
